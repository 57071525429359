import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FolderIcon from '@mui/icons-material/Folder';
import ArticleIcon from '@mui/icons-material/Article';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

const availableRoles = [
  {
    name: 'Contributor',
    description:
      'Has access to all three managers (Pipeline, Deployment, and Process), including logs visibility.',
    children: [
      {
        name: 'Pipeline_Manager',
        description: 'Handles model training and export.',
        children: [
          { name: 'Model_Training', description: 'Manages training models.' },
          { name: 'Model_Export', description: 'Exports trained models.' },
        ],
      },
      {
        name: 'Deployment_Manager',
        description: 'Handles model deployment and batch interference.',
        children: [
          {
            name: 'Model_Deployment',
            description: 'Deploys trained models.',
          },
          {
            name: 'Batch_Interference',
            description: 'Performs batch predictions.',
          },
          {
            name: 'Model_Terminate',
            description: 'Terminates active models.',
          },
        ],
      },
      {
        name: 'Process_Manager',
        description: 'Manages running pipelines and cleanup.',
        children: [
          {
            name: 'Manage_Running_Pipeline',
            description: 'Oversees active pipelines.',
          },
          {
            name: 'Pipeline_Cleanup',
            description: 'Cleans up pipeline artifacts.',
          },
        ],
      },
      {
        name: 'logs_visibility',
        description: 'Allows visibility of logs for error and job tracking.',
      },
    ],
  },
];

const adminPermissions = [
  {
    name: 'Manage Model Routing',
    description: 'Oversees and updates routing configurations. ALong with Deploying Mode Routing.',
  },
  {
    name: 'Manage API Key',
    description: 'Handles User API key generation and management.',
  },
  {
    name: 'Model Audit and Logs',
    description: 'Audits models and provides logs for transparency.',
  },
  {
    name: 'Process Manager',
    description: 'Manages all running pipelines and Pipeline CleanUp',
  },
];

const AccordionRoleManagement = () => {
  const renderSubRoles = (subRole) => (
    <Box
      sx={{
        padding: 2,
        backgroundColor: '#f0f4f8',
        borderRadius: 1,
        marginBottom: 1,
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
      }}
    >
      <Typography variant="body1" sx={{ fontWeight: 'bold', color: '#333' }}>
        {subRole.name}
      </Typography>
      <Typography variant="body2" sx={{ color: '#555' }}>
        {subRole.description}
      </Typography>
    </Box>
  );

  const renderRoleContent = (role) => (
    <Box sx={{ padding: 3 }}>
      <Typography
        variant="h5"
        sx={{
          fontWeight: 'bold',
          color: '#1976d2',
          marginBottom: 2,
        }}
      >
        {role.name}
      </Typography>
      <Typography variant="body1" sx={{ color: '#333', marginBottom: 3 }}>
        {role.description}
      </Typography>

      <Grid container spacing={3}>
        {role.children?.map((childRole, index) => (
          <Grid item xs={12} md={6} lg={4} key={index}>
            <Accordion
              sx={{
                backgroundColor: '#e3f2fd',
                '&:hover': {
                  backgroundColor: '#d1e8ff',
                },
              }}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Box display="flex" alignItems="center">
                  {childRole.name === 'logs_visibility' ? (
                    <ArticleIcon sx={{ marginRight: 1, color: '#1976d2' }} />
                  ) : (
                    <FolderIcon sx={{ marginRight: 1, color: '#1976d2' }} />
                  )}
                  <Typography sx={{ fontWeight: 'bold', color: '#333' }}>
                    {childRole.name}
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body2" sx={{ marginBottom: 2 }}>
                  {childRole.description}
                </Typography>
                {childRole.children &&
                  childRole.children.map((subRole, subIndex) => (
                    <Box key={subIndex}>{renderSubRoles(subRole)}</Box>
                  ))}
              </AccordionDetails>
            </Accordion>
          </Grid>
        ))}
      </Grid>
    </Box>
  );

  const renderAdminPermissions = () => (
    <Card
      sx={{
        backgroundColor: '#fff3e0',
        padding: 3,
        margin: 4,
        borderRadius: 2,
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      }}
    >
      <Typography
        variant="h5"
        sx={{
          fontWeight: 'bold',
          color: '#f57c00',
          marginBottom: 2,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <AdminPanelSettingsIcon sx={{ marginRight: 1 }} />
        Admin Permissions (Only Through UI)
      </Typography>
      <Grid container spacing={3}>
        {adminPermissions.map((permission, index) => (
          <Grid item xs={12} md={6} lg={3} key={index}>
            <Card
              sx={{
                backgroundColor: '#ffffff',
                padding: 2,
                borderRadius: 1,
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                '&:hover': {
                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                },
              }}
            >
              <CardContent>
                <Typography
                  variant="body1"
                  sx={{ fontWeight: 'bold', color: '#333' }}
                >
                  {permission.name}
                </Typography>
                <Typography variant="body2" sx={{ color: '#555' }}>
                  {permission.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Card>
  );

  return (
    <Box sx={{ width: '100%', padding: 4, backgroundColor: '#f9f9f9' }}>
      <Typography
        variant="h4"
        sx={{
          textAlign: 'center',
          marginBottom: 4,
          color: '#1976d2',
          fontWeight: 'bold',
        }}
      >
        Role Information
      </Typography>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            Contributor
          </Typography>
        </AccordionSummary>
        <AccordionDetails>{renderRoleContent(availableRoles[0])}</AccordionDetails>
      </Accordion>
      {renderAdminPermissions()}
    </Box>
  );
};

export default AccordionRoleManagement;
