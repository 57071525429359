export const API_BASE_URL = 'https://mlstudio.teaminnovatics.com';
// export const API_BASE_URL = 'http://127.0.0.1:8000';

export const availableRoles = [
    'Contributor',
    'Pipeline_Manager',
    'Model_Training',
    'Model_Export',
    'logs_visibility',
    'Deployment_Manager',
    'Model_Deployment',
    'Batch_Interference',
    'Model_Terminate',
    'Process_Manager',
    'Manage_Running_Pipeline',
    'Pipeline_Cleanup',
];