import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { getDeployedModel } from '../services/api';
import { API_BASE_URL } from '../services/config'


const ModelDeployment = () => {
  const [models, setModels] = useState([]);
  const [loading, setLoading] = useState(false);
  const [logs, setLogs] = useState([]);
  const [isLoadingAudit, setIsLoadingAudit] = useState(false);
  const [isLoadingLog, setIsLoadingLog] = useState(false);
  const [isLoadingCard, setIsLoadingCard] = useState(false);
  const [isLoadingEvaluation, setIsLoadingEvaluation] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem('adminToken');
      setLoading(true);
      try {
        const res = await getDeployedModel(token);
        setModels(res);
      } catch (error) {
        console.error('Error fetching models:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const handleAuditModel = (model) => {
    setIsLoadingAudit(true);
    const token = localStorage.getItem('adminToken');
    axios
      .post(
        `${API_BASE_URL}/audit_model`,
        { modelname: model.modelname, 
          version: model.version, 
          endpoint: model.endpoint,
          model_card: model.model_card,
          model_evaluation: model.model_evaluation },
        { headers: { Authorization: `Bearer ${token}` }, responseType: 'blob' }
      )
      .then((response) => {
        setIsLoadingAudit(false);
        if(response.status == 200){
          const fileURL = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = fileURL;
          link.setAttribute('download', `${model.modelname}_audit.zip`);
          document.body.appendChild(link);
          link.click();
        }else if (response.status == 201){
          console.log("There was No data/audit Log available for this Endpoint")
          alert("There was No data/audit Log available for this Endpoint")
        }else{
          alert('Error fetching audit file.');
        }
      })
      .catch((error) => {
        setIsLoadingAudit(false);
        console.error('Error fetching audit:', error.response.data.message);
        alert('Error fetching audit file.');
      });
  };

  const handleGetModelLog = (model) => {
    setIsLoadingLog(true);
    const token = localStorage.getItem('adminToken');
    axios
      .post(`${API_BASE_URL}/get_model_log`, {
        modelname: model.modelname,
        version: model.version,
        endpoint: model.endpoint,
      }, { headers: { Authorization: `Bearer ${token}` } })
      .then((response) => {
        if(response.data.success){
          setIsLoadingLog(false);
          console.log(response.data.data)
          setLogs(response.data.data);
        }else{
          setIsLoadingLog(false);
          alert(response.data.message)
        }
      })
      .catch((error) => {
        setIsLoadingLog(false);
        console.error('Error fetching model logs:', error);
        alert("Somehting Went Wrong. Please try again.")
      });
  };

  const handleDownloadModelData = (filePath, modelname, fileType, setLoadingState) => {
    setLoadingState(true);
    const token = localStorage.getItem('adminToken');
    axios
      .post(`${API_BASE_URL}/get-model-data`, { filePath }, { headers: { Authorization: `Bearer ${token}` } })
      .then((response) => {
        setLoadingState(false);
        if (response.data.success) {
          const fileURL = window.URL.createObjectURL(new Blob([response.data.data], { type: 'text/html' }));
          const link = document.createElement('a');
          link.href = fileURL;
          link.setAttribute('download', `${modelname}_${fileType}.html`);
          document.body.appendChild(link);
          link.click();
        } else {
          alert(response.data.message || `Error downloading ${fileType}.`);
        }
      })
      .catch((error) => {
        setLoadingState(false);
        console.error(`Error fetching ${fileType}:`, error);
        alert(`Error fetching ${fileType}.`);
      });
  };

  const handleDownloadModelCard = (model) => {
    handleDownloadModelData(model.model_card, model.modelname, 'model_card', setIsLoadingCard);
  };

  const handleDownloadModelEvaluation = (model) => {
    handleDownloadModelData(model.model_evaluation, model.modelname, 'model_evaluation', setIsLoadingEvaluation);
  };

  return (
    <div className="model-deployment">
      <h1 className="title">Deployed Models</h1>

      {loading ? (
        <div className="loading-message">Loading models...</div>
      ) : (
        <div className="table-container">
          <table className="model-table">
            <thead>
              <tr>
                <th>ID</th>
                <th>Model Name</th>
                <th>Version</th>
                <th>Image Name</th>
                <th>Endpoint</th>
                <th>Instance ID</th>
                <th>User Name</th>
                <th>Active</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {models.map((model) => (
                <tr key={model.id}>
                  <td>{model.id}</td>
                  <td>{model.modelname}</td>
                  <td>{model.version}</td>
                  <td>{model.imagename}</td>
                  <td>{model.endpoint}</td>
                  <td>{model.instance_id}</td>
                  <td>{model.user_name || 'User not available'}</td>
                  <td>{model.active ? 'Yes' : 'No'}</td>
                  <td className="action-buttons">
                    <button
                      onClick={() => handleAuditModel(model)}
                      disabled={isLoadingAudit}
                      className="action-button audit-button"
                    >
                      {isLoadingAudit ? 'Loading...' : 'Audit Model'}
                    </button>
                    <button
                      onClick={() => handleGetModelLog(model)}
                      disabled={isLoadingLog}
                      className="action-button log-button"
                    >
                      {isLoadingLog ? 'Loading...' : 'Get Model Log'}
                    </button>
                    <button
                      onClick={() => handleDownloadModelCard(model)}
                      disabled={isLoadingCard}
                      className="action-button card-button"
                    >
                      {isLoadingCard ? 'Loading...' : 'Download Model Card'}
                    </button>
                    <button
                      onClick={() => handleDownloadModelEvaluation(model)}
                      disabled={isLoadingEvaluation}
                      className="action-button evaluation-button"
                    >
                      {isLoadingEvaluation ? 'Loading...' : 'Download Model Evaluation'}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {logs.length > 0 && (
        <div className="logs-container">
          <h2>Model Logs</h2>
          <table className="logs-table">
            <thead>
              <tr>
                <th>Month</th>
                <th>Endpoint</th>
                <th>Total Requests</th>
                <th>Total Data</th>
              </tr>
            </thead>
            <tbody>
              {logs.map((log, index) => (
                <tr key={index}>
                  <td>{log.month}</td>
                  <td>{log.Endpoint}</td>
                  <td>{log.total_requests}</td>
                  <td>{log.total_data}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default ModelDeployment;
