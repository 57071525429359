import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { login } from '../services/api';

const AdminLogin = ({ setToken }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');

    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const response = await login(username, password);
            console.log(response);
            setToken(response.data.data); // Save the token for later use
            localStorage.setItem('adminToken', response.data.data);
            const decodedToken = jwtDecode(response.data.data);
            localStorage.setItem('adminId', decodedToken.sub); // Save token in localStorage
            console.log(decodedToken);
            setMessage('Login successful!');
            navigate('/api-keys');
        } catch (error) {
            setMessage('Login failed: ' + error.response.data.data.message);
        }
    };

    useEffect(() => {
        const token = localStorage.getItem('adminToken');

        if (token) {
            try {
                const decodedToken = jwtDecode(token);
                const currentTime = Math.floor(Date.now() / 1000);
                const timeLeft = decodedToken.exp - currentTime;
                if (timeLeft > 2 * 60 * 60) {
                    navigate('/api-keys');
                } else {
                    localStorage.removeItem('adminToken');
                    setToken(null);
                    alert('Your session is about to expire. Please login again.');
                    navigate('/');
                }
            } catch (error) {
                console.error('Invalid token:', error);
                localStorage.removeItem('adminToken');
                setToken(null);
                navigate('/');
            }
        } else {
            // No token found
            setToken(null);
            navigate('/');
        }
    }, []);

    return (
        <div className="container mt-5">
            <div className="row justify-content-center">
                <div className="col-md-6">
                    <h2 className="text-center mb-4">Admin Login</h2>
                    {message && (
                        <div className="alert alert-info" role="alert">
                            {message}
                        </div>
                    )}
                    <form onSubmit={handleLogin}>
                        <div className="mb-3">
                            <label htmlFor="username" className="form-label">
                                Username
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="username"
                                placeholder="Enter username"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="password" className="form-label">
                                Password
                            </label>
                            <input
                                type="password"
                                className="form-control"
                                id="password"
                                placeholder="Enter password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </div>
                        <div className="d-grid">
                            <button type="submit" className="btn btn-primary">
                                Login
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default AdminLogin;
