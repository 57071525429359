import React, { useState, useEffect } from 'react';
import { generateApiKey } from '../services/api';
import { availableRoles } from '../services/config'

const ApiKeyGenerator = () => {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [expiry, setExpiry] = useState('');
    const [customDate, setCustomDate] = useState('');
    const [selectedRoles, setSelectedRoles] = useState([]);
    const [message, setMessage] = useState('');
    const [expiredDate, setExpiredDate] = useState('');

    const today = new Date();
    const todayDate = today.toISOString().split('T')[0];


    const handleRoleSelect = (e) => {
        const selectedValue = e.target.value;
        if (selectedValue && !selectedRoles.includes(selectedValue)) {
            setSelectedRoles([...selectedRoles, selectedValue]);
        }
        e.target.value = ''; // Reset dropdown selection
    };

    const handleRoleRemove = (roleToRemove) => {
        setSelectedRoles(selectedRoles.filter((role) => role !== roleToRemove));
    };

    const handleGenerate = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem('adminToken'); // Get token from storage

        // Calculate the expiry date if a custom date is not set
        let expiryDate;
        if (expiry === 'custom') {
            expiryDate = customDate;
        } else {
            const days = parseInt(expiry, 10);
            const futureDate = new Date();
            futureDate.setDate(today.getDate() + days);
            expiryDate = futureDate.toISOString().split('T')[0];
        }

        try {
            await generateApiKey(token, username, email, expiryDate, selectedRoles);
            setMessage('API key generated successfully!');
        } catch (error) {
            setMessage('Error generating API key: ' + error.response.data.message);
        }
    };

    useEffect(() => {
        if (expiry !== 'custom' && expiry !== '') {
            const days = parseInt(expiry, 10);
            const futureDate = new Date();
            futureDate.setDate(today.getDate() + days);
            const expiryDate = futureDate.toISOString().split('T')[0];
            setExpiredDate(expiryDate);
        } else {
            setExpiredDate('');
        }
    }, [expiry]);

    return (
        <div className="container mt-5">
            <h2 className="mb-4">Generate API Key</h2>

            {message && (
                <div className="alert alert-info" role="alert">
                    {message}
                </div>
            )}

            <form onSubmit={handleGenerate}>
                <div className="mb-3">
                    <label htmlFor="username" className="form-label">
                        Username
                    </label>
                    <input
                        type="text"
                        className="form-control"
                        id="username"
                        placeholder="Enter username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                </div>

                <div className="mb-3">
                    <label htmlFor="email" className="form-label">
                        Email
                    </label>
                    <input
                        type="email"
                        className="form-control"
                        id="email"
                        placeholder="Enter email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </div>

                <div className="mb-3">
                    <label htmlFor="roles" className="form-label">
                        User Roles
                    </label>
                    <select
                        id="roles"
                        className="form-select"
                        onChange={handleRoleSelect}
                    >
                        <option value="">Select a role</option>
                        {availableRoles.map((role, index) => (
                            <option key={index} value={role}>
                                {role}
                            </option>
                        ))}
                    </select>
                    <div className="mt-2">
                        {selectedRoles.map((role, index) => (
                            <span
                                key={index}
                                className="badge bg-primary me-2"
                                style={{ cursor: 'pointer' }}
                                onClick={() => handleRoleRemove(role)}
                                title="Click to remove"
                            >
                                {role} &times;
                            </span>
                        ))}
                    </div>
                </div>

                <div className="mb-3">
                    <label htmlFor="expiry" className="form-label">
                        Expiry{' '}
                        {expiry !== 'custom' && expiry !== '' ? `( Expires at ${expiredDate} )` : ''}
                    </label>
                    <select
                        id="expiry"
                        className="form-select"
                        value={expiry}
                        onChange={(e) => {
                            const selectedValue = e.target.value;
                            setExpiry(selectedValue);
                            if (selectedValue === 'custom') {
                                setCustomDate(todayDate); // Set default custom date to today
                            }
                        }}
                    >
                        <option value="">Select expiry</option>
                        <option value="7">7 days</option>
                        <option value="30">30 days</option>
                        <option value="60">60 days</option>
                        <option value="90">90 days</option>
                        <option value="custom">Custom</option>
                    </select>
                    {expiry === 'custom' && (
                        <input
                            type="date"
                            className="form-control mt-2"
                            value={customDate}
                            min={todayDate}
                            onChange={(e) => setCustomDate(e.target.value)}
                        />
                    )}
                </div>

                <button type="submit" className="btn btn-primary">
                    Generate API Key
                </button>
            </form>
        </div>
    );
};

export default ApiKeyGenerator;
