import React, { useEffect, useState } from 'react';
import { getApiKeys, deleteApiKey, assignRole, unassignRole } from '../services/api';
import copy from 'copy-to-clipboard'; // Copy to clipboard function
import { Modal, Button, Spinner } from 'react-bootstrap'; // Bootstrap Modal and Spinner for better UI

import { availableRoles } from '../services/config'

const ApiKeyList = () => {
    const [apiKeys, setApiKeys] = useState([]);
    const [message, setMessage] = useState({});
    const [visibility, setVisibility] = useState({});
    const [loading, setLoading] = useState(false);
    const [selectedApiKey, setSelectedApiKey] = useState(null); // For managing the selected user for role management
    const [showRoleModal, setShowRoleModal] = useState(false); // For controlling the modal display


    useEffect(() => {
        const fetchApiKeys = async () => {
            const token = localStorage.getItem('adminToken');
            try {
                const response = await getApiKeys(token);
                setApiKeys(response.data.data);
            } catch (error) {
                setMessage({
                    type: 'error',
                    text: 'Error fetching API keys: ' + error.response,
                });
            }
        };

        fetchApiKeys();
    }, []);

    const handleDelete = async (apiKeyId) => {
        const token = localStorage.getItem('adminToken');
        try {
            await deleteApiKey(token, apiKeyId);
            setMessage({
                type: 'success',
                text: 'API key deleted successfully!',
            });
            setApiKeys(apiKeys.filter((apiKey) => apiKey.id !== apiKeyId));
        } catch (error) {
            setMessage({
                type: 'error',
                text: 'Error deleting API key: ' + error,
            });
        }
    };

    const toggleVisibility = (id) => {
        setVisibility((prevState) => ({
            ...prevState,
            [id]: !prevState[id],
        }));
    };

    const handleCopy = (apiKey) => {
        copy(apiKey);
        setMessage({
            type: 'info',
            text: 'API key copied to clipboard!',
        });
    };

    const handleRoleChange = async (action, role) => {
        setLoading(true);
        const token = localStorage.getItem('adminToken');
        const username = selectedApiKey?.username;

        try {
            let res;
            if (action === 'assign') {
                res = await assignRole(token, username, [role]);
            } else {
                res = await unassignRole(token, username, [role]);
            }
            console.log(res)
            if (res.status ==200 && res.data["success"]){
                setMessage({
                    type: 'success',
                    text: `Role ${action === 'assign' ? 'assigned' : 'unassigned'} successfully!`,
                });
            }else{
                setMessage({
                    type: 'fail',
                    text: `Role ${action === 'assign' ? 'assigned' : 'unassigned'} un-successfull!`,
                });
            }

            // Re-fetch API keys to reflect the changes
            const response = await getApiKeys(token);
            setApiKeys(response.data.data);
        } catch (error) {
            setMessage({
                type: 'error',
                text: `Error updating role: ${error}`,
            });
        } finally {
            setLoading(false);
            setShowRoleModal(false); // Close modal after action
        }
    };

    const handleOpenRoleModal = (apiKey) => {
        setSelectedApiKey(apiKey);
        setShowRoleModal(true);
    };

    // Split the available roles into assigned and unassigned based on selected user roles
    const splitRoles = () => {
        const assignedRoles = selectedApiKey?.user_role || [];
        const assignableRoles = availableRoles.filter(role => !assignedRoles.includes(role));

        return { assignedRoles, assignableRoles };
    };

    const { assignedRoles, assignableRoles } = splitRoles();

    return (
        <div className="container mt-5">
            <h2>API Key List</h2>
            {message.text && (
                <div
                    className={`alert ${message.type === 'error' ? 'alert-danger' : 'alert-info'}`}
                    role="alert"
                >
                    {message.text}
                </div>
            )}

            <table className="table table-striped table-bordered">
                <thead>
                    <tr>
                        <th>Username</th>
                        <th>Email</th>
                        <th>API Key</th>
                        <th>Expiry (Days)</th>
                        <th>Expired</th>
                        <th>Roles</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {apiKeys.map((apiKey) => (
                        <tr key={apiKey.id}>
                            <td>{apiKey.username}</td>
                            <td>{apiKey.email}</td>
                            <td>
                                {visibility[apiKey.id] ? apiKey.api_key : 'xxxxxxxxxxxxx'}
                                <button
                                    className="btn btn-link btn-sm ms-2"
                                    onClick={() => toggleVisibility(apiKey.id)}
                                >
                                    {visibility[apiKey.id] ? 'Hide' : 'Show'}
                                </button>
                                <button
                                    className="btn btn-link btn-sm ms-2"
                                    onClick={() => handleCopy(apiKey.api_key)}
                                >
                                    Copy
                                </button>
                            </td>
                            <td>{apiKey.expiry}</td>
                            <td>{apiKey.expired ? 'Yes' : 'No'}</td>
                            <td>
                                <div className="d-flex flex-wrap">
                                    {apiKey.user_role?.map((role, index) => (
                                        <span key={index} className="badge bg-primary me-2 mb-2">
                                            {role}
                                        </span>
                                    ))}
                                    <button
                                        className="btn btn-sm btn-secondary ms-2"
                                        onClick={() => handleOpenRoleModal(apiKey)}
                                    >
                                        Manage Roles
                                    </button>
                                </div>
                            </td>
                            <td>
                                <button
                                    className="btn btn-danger btn-sm"
                                    onClick={() => handleDelete(apiKey.api_key)}
                                >
                                    Delete
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {/* Modal for Role Assignment */}
            <Modal show={showRoleModal} onHide={() => setShowRoleModal(false)} dialogClassName="custom-modal">
                <Modal.Header closeButton>
                    <Modal.Title>Manage Roles for {selectedApiKey?.username}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        {/* Assigned Roles Section */}
                        <div className="col-6">
                            <h5>Assigned Roles</h5>
                            <ul className="list-group">
                                {assignedRoles.map((role) => (
                                    <li key={role} className="list-group-item d-flex justify-content-between align-items-center">
                                        {role}
                                        <button
                                            className="btn btn-sm btn-danger"
                                            onClick={() => handleRoleChange('unassign', role)}
                                        >
                                            Unassign
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        </div>

                        {/* Assignable Roles Section */}
                        <div className="col-6">
                            <h5>Assignable Roles</h5>
                            <ul className="list-group">
                                {assignableRoles.map((role) => (
                                    <li key={role} className="list-group-item d-flex justify-content-between align-items-center">
                                        {role}
                                        <button
                                            className="btn btn-sm btn-primary"
                                            onClick={() => handleRoleChange('assign', role)}
                                        >
                                            Assign
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    {loading && (
                        <div className="d-flex justify-content-center mt-3">
                            <Spinner animation="border" variant="primary" />
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowRoleModal(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default ApiKeyList;
