import React, { useState, useEffect } from "react";
import Form from '@rjsf/mui';
import { Box, Button, Typography } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import validator from "@rjsf/validator-ajv8"; // Import the validator

// JSON Schema for the form (no changes)
const schema = {
  "type": "object",
  "properties": {
    "routing_key": { "type": "string", "title": "Routing Key" },
    "rules": {
      "type": "array",
      "title": "Routing Rules",
      "items": {
        "type": "object",
        "properties": {
          "type": { "type": "string", "enum": ["if", "elif"], "title": "Rule Type" },
          "conditions": {
            "type": "array",
            "title": "Conditions",
            "items": {
              "type": "object",
              "properties": {
                "key": { "type": "string", "title": "Condition Key" },
                "operator": {
                  "type": "string",
                  "enum": [">", "<", "=", ">=", "<=", "!=", "in"],
                  "title": "Operator"
                },
                "value": { "type": "string", "title": "Condition Value" },
                "logic": { "type": "string", "enum": ["AND", "OR"], "title": "Logic" },
                "group": {
                  "type": "array",
                  "title": "Group Conditions",
                  "items": {
                    "type": "object",
                    "properties": {
                      "key": { "type": "string", "title": "Condition Key" },
                      "operator": {
                        "type": "string",
                        "enum": [">", "<", "=", ">=", "<=", "!=", "in"],
                        "title": "Operator"
                      },
                      "value": { "type": "string", "title": "Condition Value" },
                      "logic": { "type": "string", "enum": ["AND", "OR"], "title": "Logic" }
                    }
                  }
                }
              },
              "required": ["key", "operator", "value", "logic"]
            }
          },
          "endpoint": { "type": "string", "title": "Endpoint URL" }
        },
        "required": ["type", "conditions", "endpoint"]
      }
    }
  },
  "required": ["routing_key", "rules"]
}


// UI Schema for styling
const uiSchema = {
  routing_key: {
    "ui:placeholder": "Enter the routing key (e.g., 'gaming-model')",
    "ui:widget": "text", 
  },
  rules: {
    "ui:options": {
      addable: true,  
      removable: true, 
      orderable: true, 
    },
    items: {
      type: {
        "ui:widget": "select", 
      },
      conditions: {
        "ui:options": {
          addable: true,  
          removable: true, 
        },
        group: {
          "ui:options": {
            addable: true, // Allow users to add condition groups
            removable: true,
          },
        },
      },
    },
  },
};

const AddEditRuleForm = ({
  routingKey,
  setRoutingKey,
  rules,
  setRules,
  onSave,
}) => {
  const handleFormChange = ({ formData }) => {
    if (formData.rules.length > 0) {
      if (formData.rules[0].type !== "if") {
        formData.rules[0].type = "if";
      }
      const hasMultipleIf = formData.rules.filter((rule) => rule.type === "if").length > 1;
      if (hasMultipleIf) {
        formData.rules = formData.rules.filter((rule, index) =>
          index === 0 ? true : rule.type !== "if"
        );
      }
    }
    setRoutingKey(formData.routing_key);
    setRules(formData.rules);
  };

  const handleFormSubmit = ({ formData }) => {
    console.log("Submitted Routing Config:", formData);
    onSave(formData);
  };

  const handleReset = () => {
    setRoutingKey("");
    setRules([{
      type: "if",
      conditions: [{ key: "", operator: "=", value: "", logic: "AND" }],
      endpoint: "",
    }]);
  };

  useEffect(() => {
    setRoutingKey(routingKey);
    setRules(rules);
  }, [routingKey, rules, setRoutingKey, setRules]);

  return (
    <Box sx={{ padding: 2, border: "1px solid #ddd", borderRadius: "8px", backgroundColor: "#fff", maxWidth: 1200, margin: 'auto' }}>
      <Typography variant="h6" gutterBottom sx={{ fontSize: "1rem" }}>
        Routing Rule Configuration
      </Typography>
      <Form
        schema={schema}
        uiSchema={uiSchema}
        formData={{ routing_key: routingKey, rules }}
        onChange={handleFormChange}
        onSubmit={handleFormSubmit}
        validator={validator}
      >
        <Box sx={{ mt: 1, display: "flex", justifyContent: "space-between" }}>
          <Button
            type="submit"
            variant="contained"
            size="small"
            startIcon={<AddCircleIcon />}
            sx={{ padding: '6px 12px' }}
          >
            Save Configuration
          </Button>
          <Button
            variant="outlined"
            size="small"
            onClick={handleReset}
            startIcon={<RemoveCircleIcon />}
            sx={{ padding: '6px 12px' }}
          >
            Reset
          </Button>
        </Box>
      </Form>
    </Box>
  );
};

export default AddEditRuleForm;
