import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';  // Use 'Routes' instead of 'Switch'
import AdminLogin from './components/AdminLogin';
import ModelDeployment from './components/ManageModel';
import ManageRoute from './components/ManageRoute'
import ApiManagement from './components/ManageApiKey';
import Header from './components/Header';
import UpdatePassword from './components/UpdatePassword';
import ModelRoutingConfig from './components/ModelRoutingConfig'
import ManagePipeline from './components/ManagePipeline'
import 'bootstrap/dist/css/bootstrap.min.css';
import "./App.css"

const App = () => {
    const [token, setToken] = useState(localStorage.getItem('adminToken') || null);

    return ( 
        <Router>
            <Header />
            <Routes>
                <Route path="/manage-pipeline" element={token ? <ManagePipeline /> : <AdminLogin setToken={setToken} />} />
                <Route path="/manage-route" element={token ? <ManageRoute /> : <AdminLogin setToken={setToken} />} />
                <Route path="/manage-model" element={token ? <ModelDeployment /> : <AdminLogin setToken={setToken} />} />
                <Route path="/api-keys" element={token ? <ApiManagement /> : <AdminLogin setToken={setToken} />} />
                <Route path="/model-config" element={token ? <ModelRoutingConfig /> : <AdminLogin setToken={setToken} />} />
                <Route path="/change-password" element={token ? <UpdatePassword /> : <AdminLogin setToken={setToken} />} />
                <Route path="/" element={<AdminLogin setToken={setToken} />} />
            </Routes>
        </Router>
    );
};

export default App;
