import React from "react";
import { Box, Button, Typography, IconButton, Grid } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

const ShowConfigurations = ({ configs, onEdit, onDelete }) => {
  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Saved Configurations
      </Typography>
      {configs.map((config, index) => (
        <Box
          key={index}
          sx={{
            marginBottom: 2,
            border: "1px solid #ccc",
            padding: 2,
            borderRadius: 2,
          }}
        >
          <Typography variant="body1">Routing Key: {config.routing_key}</Typography>
          {config.rules.map((rule, ruleIndex) => (
            <Box key={ruleIndex} sx={{ marginLeft: 2 }}>
              <Typography>
                Rule {ruleIndex + 1}: {rule.type.toUpperCase()}
              </Typography>
              <Typography>Conditions:</Typography>
              <ul>
                {rule.conditions.map((condition, conditionIndex) => (
                  <li key={conditionIndex}>
                    {condition.key} "{condition.value}" {condition.logic}
                  </li>
                ))}
              </ul>
              <Typography>Endpoint: {rule.endpoint}</Typography>
            </Box>
          ))}
          <Grid container spacing={1} sx={{ marginTop: 2 }}>
            <Grid item>
              <IconButton color="primary" onClick={() => onEdit(config)}>
                <EditIcon />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton
                color="secondary"
                onClick={() => onDelete(config.routing_key)}
              >
                <DeleteIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Box>
      ))}
    </Box>
  );
};

export default ShowConfigurations;
