import React, { useState } from 'react';
import ProcessManager from './ProcessManager'
import PipelineCleanupPage from './PipelineCleanupPage'

const ManagePipeline = () => {
  const [activeTab, setActiveTab] = useState('processManager');

  const clearData = (tabName) => {
    alert(`All data related to the ${tabName} pipeline has been cleared.`);
  };

  return (
    <div className="unique-tabs-page-container">
      <h2 className="unique-page-title">Pipeline Management</h2>
      
      <div className="unique-tabs-container">
        <div
          className={`unique-tab ${activeTab === 'processManager' ? 'unique-active' : ''}`}
          onClick={() => setActiveTab('processManager')}
        >
          Process Manager
        </div>
        <div
          className={`unique-tab ${activeTab === 'pipelineCleanup' ? 'unique-active' : ''}`}
          onClick={() => setActiveTab('pipelineCleanup')}
        >
          Pipeline Cleanup
        </div>
      </div>

      <div className="unique-tabs-content-container">
        {activeTab === 'processManager' && (
          <div className="unique-tab-content">
            <ProcessManager />
          </div>
        )}

        {activeTab === 'pipelineCleanup' && (
          <div className="unique-tab-content">
            <PipelineCleanupPage />
          </div>
        )}
      </div>
    </div>
  );
};

export default ManagePipeline;
