import React, { useState, useEffect } from 'react';
import { getAllRunningPipelines, killJob } from '../services/api';
import { Modal, Button, Badge } from 'react-bootstrap'; // Using React Bootstrap


const ProcessManager = () => {
  const [pipelinesGrouped, setPipelinesGrouped] = useState({});
  const [loading, setLoading] = useState(true);
  const [selectedPipeline, setSelectedPipeline] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const adminId = localStorage.getItem('adminId');
  const token = localStorage.getItem('adminToken');

  useEffect(() => {
    fetchRunningPipelines();
  }, []);

  const fetchRunningPipelines = async () => {
    try {
      setLoading(true);
      const response = await getAllRunningPipelines(adminId, token);
      if (response.data.success) {
        const groupedPipelines = response.data.data.reduce((acc, job) => {
          const { pipeline_id } = job;
          if (!acc[pipeline_id]) {
            acc[pipeline_id] = [];
          }
          acc[pipeline_id].push(job);
          return acc;
        }, {});
        setPipelinesGrouped(groupedPipelines);
      } else {
        console.error('Failed to fetch running pipelines');
      }
    } catch (error) {
      console.error('Error fetching running pipelines:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleKillJob = async (workerId) => {
    const reason = prompt('Enter the reason for killing the job:');
    if (!reason) {
      alert('Reason is required to kill a job.');
      return;
    }

    try {
      const response = await killJob(workerId, reason, token);
      if (response.data.success) {
        alert('Job killed successfully!');
        fetchRunningPipelines(); // Refresh the pipelines
      } else {
        alert('Failed to kill the job:', response.data.message);
      }
    } catch (error) {
      console.error('Error killing job:', error);
      alert('Error occurred while killing the job.');
    }
  };

  const openModal = (pipelineId) => {
    setSelectedPipeline(pipelinesGrouped[pipelineId]);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedPipeline(null);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="dashboard-pipelines-container">
      <h2 className="dashboard-title">Running Pipelines</h2>
      {Object.keys(pipelinesGrouped).length === 0 ? (
        <p className="no-pipelines">No running pipelines found.</p>
      ) : (
        <div className="pipeline-card-grid">
          {Object.entries(pipelinesGrouped).map(([pipelineId, jobs]) => (
            <div className="pipeline-card" key={pipelineId}>
              <div className="pipeline-card-header">
                <h3>Pipeline ID: {pipelineId}</h3>
                <Badge
                  bg={
                    jobs.length > 10
                      ? 'danger'
                      : jobs.length > 5
                      ? 'warning'
                      : 'success'
                  }
                >
                  {jobs.length} Jobs
                </Badge>
              </div>
              <p>User: {jobs[0].user_name}</p>
              <Button
                variant="primary"
                onClick={() => openModal(pipelineId)}
              >
                View Details
              </Button>
            </div>
          ))}
        </div>
      )}

      {/* Modal for Job Details */}
      <Modal show={showModal} onHide={closeModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Pipeline Jobs</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedPipeline && (
            <table className="job-table">
              <thead>
                <tr>
                  <th>Job Type</th>
                  <th>Function</th>
                  <th>Worker ID</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {selectedPipeline.map((job) => (
                  <tr key={job.job_id}>
                    <td>{job.job_type}</td>
                    <td>{job.job_func}</td>
                    <td>{job.worker_id}</td>
                    <td>
                      <Button
                        variant="danger"
                        onClick={() => handleKillJob(job.worker_id)}
                      >
                        Kill Job
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ProcessManager;
