import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { API_BASE_URL } from '../services/config';

const ManageRoute = () => {
  const [modelRoutingName, setModelRoutingName] = useState('');
  const [size, setSize] = useState('small');
  const [port, setPort] = useState('');
  const [deploymentStatus, setDeploymentStatus] = useState(null);
  const [deployedModels, setDeployedModels] = useState([]);
  const [isLoading, setIsLoading] = useState(false); // General loading state
  const [isDeploying, setIsDeploying] = useState(false); // Specific to deployment
  const [isTerminating, setIsTerminating] = useState(false); // For terminate action
  const [error, setError] = useState(null); // To display error messages

  const adminToken = localStorage.getItem('adminToken');
  
  // User details (hardcoded as per your requirements)
  const userDetail = {
    user_id: 'admin',
    user_name: 'admin',
    user_email: '',
    api_key: adminToken,
    user_role: ['admin'],
  };

  // Function to initiate model deployment
  const handleDeployModel = async () => {
    setIsDeploying(true);
    setDeploymentStatus({ message: 'Started Deploying...' });
    setError(null); // Reset error state before deployment

    const requestData = {
      model_routing_name: modelRoutingName,
      size: size,
      port: parseInt(port, 10),
      user_detail: userDetail,
    };

    try {
      const response = await axios.post(
        `${API_BASE_URL}/model_routing_deployment`,
        requestData,
        {
          headers: { Authorization: `Bearer ${adminToken}` },
        }
      );

      setIsDeploying(false); // Stop loading after response
      if (response.data.success) {
        const deploymentId = parseInt(response.data.data, 10);
        fetchDeploymentStatus(deploymentId);
      } else {
        setError('Deployment failed: ' + response.data.message);
      }
    } catch (error) {
      setIsDeploying(false);
      setError('Deployment failed. Please try again.');
      console.error('Error deploying model:', error);
    }
  };

  // Function to fetch deployment status
  const fetchDeploymentStatus = (id) => {
    const intervalId = setInterval(async () => {
      try {
        setIsLoading(true); // Show loading when status is being fetched
        const response = await axios.get(`${API_BASE_URL}/deploymnet_status?id=${id}`, {
          headers: { Authorization: `Bearer ${adminToken}` },
        });
        
        setIsLoading(false); // Hide loading when response is received
        if (response.data.success) {
          const statusMessage = response.data.message;
          const statusData = response.data.data;
          setDeploymentStatus({ message: statusMessage, endpoint: statusData });

          if (statusMessage === 'SUCCESS') {
            clearInterval(intervalId); // Stop polling when deployment is successful
          } else if (statusMessage === 'Failed') {
            clearInterval(intervalId); // Stop polling on failure
            setDeploymentStatus({ message: "Model Deployment couldn't deploy." });
          }
        } else {
          setError('Error fetching deployment status.');
          console.error('Error fetching deployment status:', response.data.message);
        }
      } catch (error) {
        setIsLoading(false);
        setError('Error fetching deployment status.');
        console.error('Error fetching deployment status:', error);
      }
    }, 40000); // Fetch status every 40 seconds
  };

  // Fetch all deployed models
  const fetchDeployedModels = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${API_BASE_URL}/routing_deployed_model`, {
        headers: { Authorization: `Bearer ${adminToken}` },
      });
      setIsLoading(false);
      if (response.data.success) {
        setDeployedModels(response.data.data);
      } else {
        setError('Error fetching deployed models.');
      }
    } catch (error) {
      setIsLoading(false);
      setError('Error fetching deployed models.');
      console.error('Error fetching deployed models:', error);
    }
  };

  // Handle terminate model
  const handleTerminateModel = async (model) => {
    setIsTerminating(true);
    const requestData = {
      modelname: model.modelname,
      version: model.version,
      endpoint: model.endpoint,
      user_detail: userDetail,
    };
    try {
      const response = await axios.post(
        `${API_BASE_URL}/model_routing_deployment/terminate`,
        requestData,
        {
          headers: { Authorization: `Bearer ${adminToken}` },
        }
      );
      setIsTerminating(false);
      if (response.data.success) {
        alert('Model terminated successfully.');
        fetchDeployedModels(); // Refresh the list of deployed models
      } else {
        setError('Failed to terminate model: ' + response.data.message);
      }
    } catch (error) {
      setIsTerminating(false);
      setError('Error terminating model.');
      console.error('Error terminating model:', error);
    }
  };

  useEffect(() => {
    fetchDeployedModels();
  }, []);

  return (
    <div className="route-container">
      <h2>Model Routing Deployment</h2>

      {/* Model Deployment Form */}
      <div className="form-group">
        <label>Model Routing Name</label>
        <input
          type="text"
          className="form-control"
          value={modelRoutingName}
          onChange={(e) => setModelRoutingName(e.target.value)}
          placeholder="Enter model routing name"
        />
      </div>

      <div className="form-group">
        <label>Size</label>
        <select
          className="form-control"
          value={size}
          onChange={(e) => setSize(e.target.value)}
        >
          <option value="small">Small</option>
          <option value="medium">Medium</option>
          <option value="large">Large</option>
        </select>
      </div>

      <div className="form-group">
        <label>Port</label>
        <input
          type="number"
          className="form-control"
          value={port}
          onChange={(e) => setPort(e.target.value)}
          placeholder="Enter port number"
        />
      </div>

      <button
        className="btn btn-primary"
        onClick={handleDeployModel}
        disabled={isDeploying}
      >
        {isDeploying ? 'Deploying...' : 'Deploy Model'}
      </button>

      {/* Loading Spinner */}
      {isLoading && (
        <div className="loading-indicator">
          <span>Loading...</span>
        </div>
      )}

      {/* Error Message */}
      {error && <div className="error-message">{error}</div>}

      {/* Deployment Status */}
      {deploymentStatus && (
        <div className="status-container">
          <h3>Status: {deploymentStatus.message}</h3>
          {deploymentStatus.message === 'SUCCESS' && (
            <div>
              <p>Deployment successful! Endpoint: {deploymentStatus.endpoint}</p>
            </div>
          )}
        </div>
      )}

      {/* List of Deployed Models */}
      <h3>Deployed Models</h3>
      {deployedModels.length === 0 && <p>No models deployed yet.</p>}
      <table className="table">
        <thead>
          <tr>
            <th>Model Name</th>
            <th>Version</th>
            <th>Endpoint</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {deployedModels.map((model) => (
            <tr key={model.id}>
              <td>{model.modelname}</td>
              <td>{model.version}</td>
              <td>{model.endpoint}</td>
              <td>
                <button
                  className="btn btn-danger"
                  onClick={() => handleTerminateModel(model)}
                  disabled={isTerminating}
                >
                  {isTerminating ? 'Terminating...' : 'Terminate'}
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ManageRoute;
