import React from "react";

import ApiKeyList from './ApiKeyList'
import ApiKeyGenerator from './ApiKeyGenerator'
import RoleManagement from './RoleInformation'


const ApiManagement = () => {
  return (
    <div className="container mt-4">
      <ul className="nav nav-tabs" id="apiTabs" role="tablist">
        <li className="nav-item" role="presentation">
          <button
            className="nav-link active"
            id="manage-api-tab"
            data-bs-toggle="tab"
            data-bs-target="#manage-api"
            type="button"
            role="tab"
            aria-controls="manage-api"
            aria-selected="true"
          >
            Manage API & Roles
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button
            className="nav-link"
            id="api-keys-tab"
            data-bs-toggle="tab"
            data-bs-target="#api-keys"
            type="button"
            role="tab"
            aria-controls="api-keys"
            aria-selected="false"
          >
            API Keys & Updates
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button
            className="nav-link"
            id="role-management-tab"
            data-bs-toggle="tab"
            data-bs-target="#role-management"
            type="button"
            role="tab"
            aria-controls="role-management"
            aria-selected="false"
          >
            Role Information
          </button>
        </li>
      </ul>
      <div className="tab-content" id="apiTabsContent">
        <div
          className="tab-pane fade show active"
          id="manage-api"
          role="tabpanel"
          aria-labelledby="manage-api-tab"
        >
          <div className="p-3">
            <ApiKeyGenerator />
          </div>
        </div>
        <div
          className="tab-pane fade"
          id="api-keys"
          role="tabpanel"
          aria-labelledby="api-keys-tab"
        >
          <div className="p-3">
            <ApiKeyList />
          </div>
        </div>
        <div
          className="tab-pane fade"
          id="role-management"
          role="tabpanel"
          aria-labelledby="role-management-tab"
        >
          <div className="p-3">
            <RoleManagement />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApiManagement;
