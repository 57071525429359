import React, { useState, useEffect } from 'react';
import { getAllPipelines, deletePipeline } from '../services/api';

const PipelineCleanupPage = () => {
  const [pipelines, setPipelines] = useState([]);
  const [loading, setLoading] = useState(true);

  const adminId = localStorage.getItem('adminId');
  const token = localStorage.getItem('adminToken');

  useEffect(() => {
    fetchAllPipelines();
  }, []);

  const fetchAllPipelines = async () => {
    try {
      setLoading(true);
      const response = await getAllPipelines(adminId, token);
      if (response.data.success) {
        setPipelines(response.data.data);
      } else {
        console.error('Failed to fetch pipelines');
      }
    } catch (error) {
      console.error('Error fetching pipelines:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleDeletePipeline = async (pipelineId, status, username) => {
    if (status != "active"){
      const confirmation = window.confirm(`This Pipeline has already been Soft Deleted By User ${username}. Do you wanna delete it Permanently.`);
      if (!confirmation) return;
    }
    const confirmation = window.confirm('Are you sure you want to delete this pipeline?');
    if (!confirmation) return;

    try {
      const response = await deletePipeline(pipelineId, token);
      if (response.data.success) {
        alert('Pipeline deleted successfully!');
        fetchAllPipelines(); // Refresh the pipelines list
      } else {
        alert('Failed to delete the pipeline.');
      }
    } catch (error) {
      console.error('Error deleting pipeline:', error);
      alert('Error occurred while deleting the pipeline.');
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="unique-pipeline-cleanup-container">
      <h2 className="unique-page-title">Pipeline Cleanup</h2>

      {pipelines.length === 0 ? (
        <p>No pipelines found to clean up.</p>
      ) : (
        <div className="unique-pipeline-card-container">
          {pipelines.map((pipeline) => (
            <div className="unique-pipeline-card" key={pipeline.pipeline_id}>
              <h3 className="unique-pipeline-name">Pipeline ID: {pipeline.pipeline_id}</h3>
              <p className="unique-pipeline-details">Pipeline Name: {pipeline.pipeline_name}</p>
              <p className="unique-pipeline-details">User: {pipeline.user_name}</p>
              <p className="unique-pipeline-details">Status: {pipeline.status}</p>
              <p className="unique-pipeline-details">Deleted Date: {pipeline.deleted_date ? pipeline.deleted_date : 'N/A'}</p>
              <button className="unique-delete-button" onClick={() => handleDeletePipeline(pipeline.pipeline_id, pipeline.status, pipeline.user_name)}>
                Delete Pipeline
              </button>
            </div>
          ))}
        </div>

      )}
    </div>
  );
};

export default PipelineCleanupPage;
